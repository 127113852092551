<template>
  <div class="bg-white rounded lg:rounded-lg xl:rounded-xl shadow">
    <carousel :perPage="perPage">
      <slide class="px-3 md:pr-3 lg:pr-6">
        <div class="flex justify-between items-center pb-5">
          <div class="flex items-center mb-4 md:mb-0">
            <img src="@/assets/img/dash.svg" alt="" class="w-24 h-24 mr-2" />
            <div>
              <h6 class="uppercase font-semibold">loan requests</h6>
            </div>
          </div>
          <p class="text-card">Today</p>
        </div>
        <div
          class="flex justify-between items-center border-b border-gray-400 pb-1 py-3"
        >
          <p class="text-card">Number of Requested Loans</p>
          <span class="font-semibold">{{ value1 }}</span>
        </div>
        <div
          class="flex justify-between items-center border-b border-gray-400 pb-1 py-3"
        >
          <p class="text-card">Value of Requested Loans</p>
          <span class="font-semibold">{{ numberValue1 }}</span>
        </div>

        <!-- <div class="flex items-center cursor-pointer">
          <p
            class="font-semibold text-dashblack"
          >
            View All
          </p>
          <img
            src="@/assets/img/arrow-right.svg"
            alt=""
            class="ml-4"
          >
        </div> -->
      </slide>
      <slide class="px-3 md:px-3 lg:px-8">
        <div class="flex justify-between items-center pb-5">
          <div class="flex items-center mb-4 md:mb-0">
            <img src="@/assets/img/dash.svg" alt="" class="w-24 h-24 mr-2" />
            <h6 class="uppercase font-semibold">loan requests</h6>
          </div>
          <p class="text-card">This Month</p>
        </div>
        <div
          class="flex justify-between items-center border-b border-gray-400 pb-1 py-3"
        >
          <p class="text-card">Number of Requested Loans</p>
          <span class="font-semibold">{{ value2 }}</span>
        </div>
        <div
          class="flex justify-between items-center border-b border-gray-400 pb-1 py-3"
        >
          <p class="text-card">Value of Requested Loans</p>
          <span class="font-semibold">{{ numberValue2 }}</span>
        </div>
      </slide>
      <slide class="px-3 md:px-3 lg:px-8" v-if="numberValue3">
        <div class="flex justify-between items-center py-5">
          <h6 class="uppercase font-semibold">loan requests</h6>
          <p class="text-card">Total</p>
        </div>
        <div
          class="flex justify-between items-center border-b border-gray-400 pb-1 py-3"
        >
          <p class="text-card">Total Number of Requested Loans</p>
          <span class="font-semibold">{{ numberValue3 }}</span>
        </div>
        <!-- <div class="flex items-center cursor-pointer">
          <p
            class="font-semibold text-dashblack"
          >
            View All
          </p>
          <img
            src="@/assets/img/arrow-right.svg"
            alt=""
            class="ml-4"
          >
        </div> -->
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  props: {
    value1: Number,
    numberValue1: Number,
    value2: Number,
    numberValue2: Number,
    numberValue3: Number,
  },
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      perPage: 1,
    };
  },
};
</script>

<style>
#big-card {
  font-family: "Lato", sans-serif;
  position: relative;
}
</style>
